@import "../../utils/theme.scss";
.container {
  position: relative;
  width: 100%;
  justify-content: center;
}

.container_submit {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.container_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  width: 33px;
  font-size: 20px;
  border: 0;
  padding: 0;
  margin: 0 4px 0 0;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  background-color: #d3d6da;
  color: #000;
  text-transform: uppercase;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

@import "../../utils/theme.scss";
.container {
  width: 100%;
}

.container_title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}
.container_content {
  display: flex;
  justify-content: center;
  margin: 12px 0;
}
.container_input {
  width: 48px;
  text-transform: uppercase !important;
  height: 48px;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  line-height: 48px;
  border-radius: 2px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 0 !important;
  margin: 0 2px 0 0 !important;
  outline: none !important;
  &-corr {
    background: #22c55e;
  }
  &-pos {
    background: #eab308;
  }
  &-col {
    background: #9ca3af;
    background-image: url(../../image/col.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
  &-row {
    background: #9ca3af;
    background-image: url(../../image/row.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}

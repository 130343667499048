@import "../../utils/theme.scss";
header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
button {
  cursor: pointer;
  margin: 0 5px 0;
}
.header {
  width: 100%;
  max-width: 550px;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.3rem;
  @media only screen and (max-width: 550px) {
    font-size: 1.5rem;
  }
}

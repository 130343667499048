@import "../../utils/theme.scss";
.container {
  width: 100%;
  margin: 12px 0;
}

.container_title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}
.container_content {
  display: flex;
  justify-content: center;
  margin: 12px 0;
}
.container_answer {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  width: 375px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0 2px 0 0;
  transition: 0.5s;
}
.container_answer_error {
  color: #a6abad;
  font-size: 32px;
}
.container_answer_word {
  text-align: center;
  width: 50%;
  font-size: 32px;
  line-height: 48px;
  font-weight: bold;
  outline: none;
}

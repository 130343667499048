body,
div,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Noto Sans TC", sans-serif;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input {
  border: none;
}
img {
  border: 0;
}
i {
  font-style: normal;
}
li,
img,
em,
i {
  list-style: none;
  border: none;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
html {
  font-size: 10px;
  height: 100%;
  max-height: 100%;
  scroll-behavior: smooth;
}
html body {
  background-size: cover;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  max-height: 100%;
}
a {
  color: unset;
}
html body a,
html body a:active,
html body a:hover {
  cursor: pointer;
  text-decoration: none;
}
html body iframe {
  border: 0;
}
ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
input:focus,
button:focus,
textarea:focus {
  outline: none;
}
button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}
button:focus {
  outline: none;
}

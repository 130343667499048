@import "../../utils/theme.scss";
.container {
  display: flex;
  position: relative;
  justify-content: center;
  margin: 0 0 12px 0;
}

.container_button {
  width: 48px;
  text-transform: uppercase;
  height: 48px;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  line-height: 48px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #fff;
  padding: 0;
  margin: 0 10px 0 0;
  outline: none;
  cursor: pointer;
  transition: 0.5s;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.4), 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  &-corr {
    background: #22c55e;
    &:hover:not([disabled]) {
      background: #315e2d;
      transition: 0.5s;
    }
  }
  &-pos {
    background: #eab308;
    &:hover:not([disabled]) {
      background: #86752a;
      transition: 0.5s;
    }
  }
  &-col {
    background: #9ca3af;
    background-image: url(../../image/col.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    &:hover:not([disabled]) {
      background: #5d6168;
      background-image: url(../../image/col.svg);
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: auto 100%;
      transition: 0.5s;
    }
  }
  &-row {
    background: #9ca3af;
    background-image: url(../../image/row.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    &:hover:not([disabled]) {
      background: #5d6168;
      background-image: url(../../image/row.svg);
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: auto 100%;
      transition: 0.5s;
    }
  }
}
